export default {
  namespaced: true,
  state: {
    countries: [],
    incomplete_payment: JSON.parse(localStorage.getItem('incomplete_payment')) || false,
    trial_period: JSON.parse(localStorage.getItem('trial_period')) || { trial: false },
    unread_chats: parseInt(localStorage.getItem('unread_chats')) || 0,
    banned_for_proposal: JSON.parse(localStorage.getItem('banned_for_proposal')) || false,
  },
  getters: {
    allCountries: state => state.countries,
    incomplete_payment: state => state.incomplete_payment,
    trial_period: state => state.trial_period,
    unread_chats: state => state.unread_chats,
    banned_for_proposal: state => state.banned_for_proposal,
  },
  mutations: {
    SET_COUNTRIES(state, val) {
      state.countries = val
    },
    SET_INCOMPLETE_PAYMENT(state, val) {
      state.incomplete_payment = val;
      localStorage.setItem('incomplete_payment', JSON.stringify(val));
    },
    SET_TRIAL_PERIOD(state, val) {
      state.trial_period = val;
      localStorage.setItem('trial_period', JSON.stringify(val));
    },
    SET_UNREAD_CHATS(state, val) {
      state.unread_chats = val;
      localStorage.setItem('unread_chats', val);
    },
    SET_BANNED_FOR_PROPOSAL(state, val) {
      state.banned_for_proposal = val;
      localStorage.setItem('banned_for_proposal', JSON.stringify(val));
    },
  },
  actions: {},
};
