import axios from './axios_interceptors'
import config from '@/services/config'
import { getUserData } from '@/libs/utils/user';

export default {
  getChatRoomProposals(proposal_uuid, creator_id, brand_id) {
    return axios.get(`${config.urlCore}/api/v3/chat/create/${proposal_uuid}/${creator_id}/${brand_id}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  updatePaymentMethod(data) {
    return axios.post(`${config.urlCore}/api/v3/plans/change-card/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  createProposal(data) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposal/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  updateProposal(proposal_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposal/${proposal_uuid}`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  statusCheckoutProposal(user_proposal_uuid) {
    return axios.get(`${config.urlCore}/api/v3/profile/proposal/status-checkout/${user_proposal_uuid}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  deleteProposals(data) {
    return axios.post(`${config.urlCore}/api/v3/profile/delete/proposal/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  resetProposal(proposal_uuid) {
    return axios.get(`${config.urlCore}/api/v3/proposals/reset/${proposal_uuid}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  addOptionOrRequest(proposal_uuid, data, element) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposal/${proposal_uuid}/add/${element}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getProposals() {
    return axios.get(`${config.urlCore}/api/v3/profile/get/proposals/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getProposalsByUser(user_id) {
    return axios.get(`${config.urlCore}/api/v3/proposals/by-user/${user_id}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getFavoriteProposals() {
    return axios.get(`${config.urlCore}/api/v3/proposals/favorites/`,{})
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getDetailProfiles(network, username) {
    return axios.get(`${config.urlCore}/api/v3/profile/${network}/${username}/detail/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  detailProposal(proposal_uuid) {
    return axios.get(`${config.urlCore}/api/v3/profile/proposal/${proposal_uuid}/`)
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  duplicateProposal(proposal_uuid) {
    return axios.get(`${config.urlCore}/api/v3/proposals/duplicate/${proposal_uuid}/`)
      .then(response => response.data)
      .catch(error => {
        return error.response
      })
  },
  deleteElement(proposal_uuid, element_uuid, element) {
    return axios.delete(`${config.urlCore}/api/v3/profile/proposal/${proposal_uuid}/${element}/${element_uuid}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  uploadImagesProposal(proposal_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposal/add/images/${proposal_uuid}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  orderImagesProposal(proposal_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposal/${proposal_uuid}/order/images/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  searchProposals(data, size_page = '', page = '1', send_token = false) {
    let url = `${config.urlCore}/api/v3/profile/search/proposals/?page=${page}`
    if (size_page) url += `&size_page=${size_page}`

    let headers = {}

    if (send_token) {
      headers = getUserData() ? { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } : {}
    }

    return axios.post(url,
      data, {headers})
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  updateElement(proposal_uuid, element_uuid, data, element) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposal/${proposal_uuid}/${element}/${element_uuid}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getPlan() {
    return axios.get(`${config.urlCore}/api/v3/plans/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  checkoutPlan(interval, plan_uuid) {
    return axios.get(`${config.urlCore}/api/v3/plans/checkout/${interval}/${plan_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  statusPlan() {
    return axios.get(`${config.urlCore}/api/v3/plans/status/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  cancelPlan() {
    return axios.get(`${config.urlCore}/api/v3/plans/cancel/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  reactivePlan() {
    return axios.get(`${config.urlCore}/api/v3/plans/reactivate/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  createCheckout(data, proposal_uuid, deal_uuid) {
    return axios.post(`${config.urlCore}/api/v3/profile/${proposal_uuid}/checkout/${deal_uuid}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getProposalsPaid(data, page, size_page) {
    return axios.post(`${config.urlCore}/api/v3/profile/proposals/paid/brand/?page=${page}&size_page=${size_page}`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getTransactions() {
    return axios.get(`${config.urlCore}/api/v3/profile/proposals/paid/creator/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getSummaryPaid() {
    return axios.get(`${config.urlCore}/api/v3/profile/summary/proposals-paid/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  myWorkSummary() {
    return axios.get(`${config.urlCore}/api/v3/profile/my-work/summary/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  sendReview(proposal_uuid) {
    return axios.get(`${config.urlCore}/api/v3/profile/proposal/send-review/${proposal_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  changeAdmin(proposal_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/proposals/admin-change/${proposal_uuid}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  unPublished(proposal_uuid) {
    return axios.get(`${config.urlCore}/api/v3/proposals/unpublished/${proposal_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  
  collaborationBanners(data) {
    return axios.post(`${config.urlCore}/api/v3/proposals/banners/`,data,)
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
}