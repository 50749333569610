import axios from './axios_interceptors'
import config from '@/services/config'
import { getUserData } from '@/libs/utils/user';

export default {
  getBrands(query) {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/get-brands/?query=${query}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getCastingCallsforUser(data, page, size_page) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/by-user/?page=${page}&size_page=${size_page}`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getCastingCall(casting_call_uuid) {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  summaryCastingCall(casting_call_uuid) {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/summary/${casting_call_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  deleteCastingCall(casting_call_uuid) {
    return axios.delete(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getPitches(data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/applied/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getPitchesCurrentMonth() {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/pitches-current-month/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getSummaryCreatorCasting() {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/creator/summary`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getPitchApliedCastingCall(casting_call_uuid) {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/ready-apply/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  deletePitch(user_apply_uuid) {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/${user_apply_uuid}/delete/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  createCastingCallIA(data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/create-with-ai/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  updateCastingCall(casting_call_uuid, data) {
    return axios.put(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data)
      .catch(error => {
        return error.response
      });
  },
  getCastingCalls(data, size_page = '', page = '1', send_token = true) {
    let url = `${config.urlCore}/api/v3/casting-calls/marketplace/?page=${page}`
    if (size_page) url += `&size_page=${size_page}`
    let headers = {}

    if (send_token) {
      headers = getUserData() ? { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } : {}
    }

    return axios.post(url,
      data,
      {headers},
    )
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  createBrand(data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/create-brand/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  createCastingCall(data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/create/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  attatchFiles(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/attach-files/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  updateFiles(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/edit-files/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  changeAdmin(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/toggle-status/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  deleteFile(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/delete-files/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  attatchLinks(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/attach-links/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  updateLinks(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/edit-links/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  deleteLink(casting_call_uuid, link_uuid) {
    return axios.delete(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/edit-link/${link_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  attatchCompensations(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/attach-compensations/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data)
      .catch(error => {
        return error.response
      });
  },
  updateCompensations(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/edit-compensations/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  deleteCompensation(casting_call_uuid, compensation_uuid) {
    return axios.delete(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/edit-compensation/${compensation_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  attatchQuestions(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/attach-questions/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  updateQuestions(casting_call_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/edit-questions/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  deleteQuestions(casting_call_uuid, question_uuid) {
    return axios.delete(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/edit-question/${question_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getCastingCallBySlug(slug) {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/detail/${slug}/`)
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  submitRevisionCastingCall(casting_call_uuid) {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/${casting_call_uuid}/send-revision/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  getSummaryWork() {
    return axios.get(`${config.urlCore}/api/v3/profile/my-work/summary/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      });
  },
  castingFilters(data) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/by-user/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  createCastingCallChat(user_apply_uuid) {
    return axios.get(`${config.urlCore}/api/v3/casting-calls/chat/${user_apply_uuid}/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
}