
import env from '@/axiosCode.js'
export default {
  urlCore: env.apiCore ? env.apiCore : 'https://core.api.brandme.la',
  urlBase: env.apiServExt ? env.apiServExt : 'https://nservices.brandme.la',
  urlApi: env.apiUrl ? env.apiUrl : 'https://api.brandme.la',
  urlPhp: env.apiPhp ? env.apiPhp : 'https://serviceext.brandme.la',
  headers : {Authorization: `Bearer ${localStorage.getItem('accessToken')}`},
  urlLaravel: env.apiLaravel ? env.apiLaravel : 'https://laravel.brndme.ly',
}
